<template>
  <div class="service-unit-setting">
    <PageTitle
      title="設備管理"
      cyBtn1="new-unit-btn"
      btn="新增"
      @btnClick="$router.push({
        name: 'ResourceEdit'
      })"
    />
    <FiltersContainer>
      <el-input v-model="nameSearch" clearable placeholder="輸入設備名稱" @keypress.enter.native="refresh" @clear="refresh">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh" />
      </el-input>
    </FiltersContainer>
    <section>
      <p>設備總數：{{ totalCount }} / 設備總數上限：{{ contractUnitCount }}</p>
      <ProgressBar :percentage="unitCountPercentage" />
    </section>

    <ResourceSettingTable
      v-loading="loading.table"
      :tableData="tableData"
      @refresh="refresh(false)"
      @edit="editResource"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import store from '@/store'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import { get } from 'lodash'
import ResourceSettingTable from './components/ResourceSettingTable.vue'

import ProgressBar from '@/components/Progress/ProgressBar'
import {
  GetResourceItem,
  GetResourceItemCount,
} from '@/api/resource'

export default defineComponent({
  name: 'ResourceSetting',
  components: { PageTitle, ProgressBar, ResourceSettingTable },
  setup (props) {
    const router = useRouter()

    const { tableData, tableOptions, tableDataCount, fetchDataCount, fetchData, loading, pageStartIndex, shopId } = useTable()
    const nameSearch = ref('')
    const totalUnitCount = ref(0)
    const userPlanLimit = computed(() => store.getters.userPlanLimit)
    const contractUnitCount = computed(() => get(userPlanLimit.value, 'resourceItemLimit'))
    const totalCount = ref(0)
    const unitCountPercentage = computed(() => {
      if (contractUnitCount.value === 0) {
        return 0
      }
      const count = Math.round(totalCount.value * 100 / contractUnitCount.value)
      return count > 100 ? 100 : count
    })

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      await getResourceItemCount()
      loading.table = false
    }
    const getTableData = async () => {
      const startIndex = pageStartIndex
      const limit = get(tableOptions, 'pageLimit')
      const payload = {
        shopId: shopId.value,
        name: nameSearch.value === '' ? undefined : nameSearch.value,
        isRemove: false,
        start: startIndex.value,
        limit,
      }
      const res = await Promise.allSettled([
        fetchData(GetResourceItem, payload),
        fetchDataCount(GetResourceItemCount, payload),
      ])
      const [, count] = res
      console.log(res, count)
    }

    const getResourceItemCount = async () => {
      const [res, err] = await GetResourceItemCount({
        shopId: shopId.value,
        name: undefined,
        isRemove: false,
      })
      totalCount.value = get(res, 'count')
      if (err) window.$message.warning(err)
    }

    const editResource = async (id) => {
      router.push({ name: 'ResourceEdit', params: { resourceId: id } })
    }

    onMounted(() => {
      refresh()
    })

    return {
      nameSearch,
      tableData,
      tableOptions,
      loading,
      unitCountPercentage,
      refresh,
      tableDataCount,
      userPlanLimit,
      totalUnitCount,
      contractUnitCount,
      editResource,
      totalCount,
    }
  },
})
</script>

<style lang="scss" scoped>
.text-btn {
  @apply text-normal text-primary-100;
}
.cancel-text-btn {
  font-size: 16px;
}
line {
  stroke: #5E5E5E;
}
.dashed-line {
  height: 13px;
  stroke-width: 0.5;
  margin-bottom: 20px;
}
</style>
