<template>
  <div class="resource-setting-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column prop="name" label="圖片" width="200" align="center">
        <template slot-scope="scope">
          <img
            style="margin: auto"
            width="90"
            :src="
              scope.row.Image ? imgSource(scope.row.Image) : ThumbnailDefault
            "
          >
        </template>
      </el-table-column>
      <el-table-column label="設備名稱" prop="name" width="200" align="center" />
      <el-table-column label="啟用狀態" width="200" align="center">
        <template slot-scope="scope">
          <Tag :type="tagType(scope.row.enabled)">{{ (scope.row.enabled) ? '開啟' : '關閉' }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="同時段可使用數量" prop="bookingMax" width="200" align="center" />
      <el-table-column prop="order" label="排序" align="center" />
      <el-table-column prop="name" label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="onEdit(scope.row.id)"
            @delete="onDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteResourceItem"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import { get, map } from 'lodash'
import { useRouter } from 'vue-router/composables'
import store from '@/store'
import { formatDate } from '@/utils/date'
import ThumbnailDefault from '@/assets/coupon/thumbnail_default.svg'
import { imgSrc } from '@/utils/helper'
import {
  DeleteResourceItem,
} from '@/api/resource'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'

export default defineComponent({
  name: 'ResourceSettingTable',
  components: { DeleteDialog },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh', 'edit', 'delete'],
  setup (props, { emit }) {
    const router = useRouter()
    const shopId = computed(() => store.getters.shop)
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })
    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const { id, Image, name, enabled, bookingMax, order } = item
        return {
          id,
          Image,
          name,
          enabled,
          bookingMax,
          order,
        }
      })
    })
    const imgSource = (image) => {
      return imgSrc(90, image || null)
    }
    const tagType = (val) => {
      let type = 'info'
      if (val) type = 'action'
      return type
    }

    const deleteResourceItem = async () => {
      const [, err] = await DeleteResourceItem({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      emit('refresh')
    }

    const onDelete = async (row) => {
      selectRow.value = row
      modal.delete = true
      // emit('delete', id)
    }

    const onEdit = (id) => {
      emit('edit', id)
    }

    return {
      ThumbnailDefault,
      displayData,
      imgSource,
      tagType,
      modal,
      deleteResourceItem,
      onDelete,
      onEdit,
      get,
    }
  },
})
</script>

<style lang="postcss" scoped>
.btn {
  @apply underline text-normal font-normal;
}
</style>
